<template>
  <app-overlay>
    <b-row>
      <b-col md="4">
        <password-change />
      </b-col>
    </b-row>
  </app-overlay>
</template>

<script>
import PasswordChange from "./PasswordChange.vue";
export default {
  components: { PasswordChange },
};
</script>

<style></style>
