<template>
  <b-card title="Şifre Güncelleme">
    <validation-observer ref="passwordchange">
      <app-input
        id="oldPassword"
        v-model="data.oldPassword"
        type="password"
        name="Eski Şifre"
        label="Eski Şifre:"
        placeholder="············"
        rules="required"
        @keyup.enter.native="changePassword"
      />
      <app-input
        id="password"
        v-model="data.newPassword"
        type="password"
        name="Yeni Şifre"
        label="Yeni Şifre:"
        placeholder="············"
        rules="required"
        @keyup.enter.native="changePassword"
      />
      <app-input
        v-model="passwordCheck"
        type="passwordCheck"
        name="Şifre Doğrulama"
        label="Şifre Doğrulama:"
        placeholder="············"
        rules="required|confirmed:password"
        @keyup.enter.native="changePassword"
      />
      <hr />
      <app-button text="Şifreyi Güncelle" block @click="changePassword" />
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationObserver } from "vee-validate";

export default {
  components: { ValidationObserver },
  data() {
    return {
      data: {},
      passwordCheck: null,
    };
  },
  methods: {
    changePassword() {
      this.$validate(this.$refs.passwordchange, () => {
        this.$axios
          .patch("/administrators/update-password", { ...this.data })
          .then(() => {
            this.data = {};
            this.passwordCheck = null;
            this.$refs.passwordchange.reset();
            this.$emitter.$emit("Notification", {
              variant: "success",
              title: "İşlem Başarılı",
              message: "Şifreniz başarıyla güncellendi.",
            });
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", {
              variant: "danger",
              title: "Hata Oluştu",
              message: "Şifreniz değiştirilemedi",
            });
          });
      });
    },
  },
};
</script>

<style></style>
